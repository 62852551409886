import './style.css'
import * as THREE from 'three'


import { PointerLockControls} from 'three/examples/jsm/controls/PointerLockControls';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { RectAreaLightUniformsLib } from "three/examples/jsm/lights/RectAreaLightUniformsLib";


//import { RoomEnvironment} from 'three/examples/jsm/environments/RoomEnvironment.js';
//import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
//import { RectAreaLightHelper }  from 'three/examples/jsm/helpers/RectAreaLightHelper.js';

//##################
//##################

const debugObject = {}
const objects = [];
let camera, scene, renderer, controls;

//audio checks
let audioIsLoaded = 0;


//movements PC
let moveForward = false;
let moveBackward = false;
let moveLeft = false;
let moveRight = false;
let canJump = false;

//movements MOBILE
let fwdValue = 0;
let bkdValue = 0;
let rgtValue = 0;
let lftValue = 0;
let tempVector = new THREE.Vector3();
let upVector = new THREE.Vector3(0, 1, 0);
let joyManager;
let meshLook;



let prevTime = performance.now();

//for movement
const velocity = new THREE.Vector3();
const direction = new THREE.Vector3();

//#######################################
//#######################################

//MOBILE OR PC 

if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
// true for mobile device
    init_Mobile();
    // added joystick + movement
    addJoystick();
}else{
    // false for not mobile device
    init_PC();
}


animate();



//#######################################
//#######################################



//###################
// FUNCTION FOR ALL GEO AND LIGHTS
//###################
function load_geo_lights(){

    //
    // Objects
    //
    const dracoLoader = new DRACOLoader();
    //dracoLoader.setDecoderPath( 'three/examples/js/libs/draco/gltf/ ' );

    //### the decoder path below needed a full path. i'm using this below for now, but I guess you'll need to do something different for the actual project
    dracoLoader.setDecoderPath( 'https://www.gstatic.com/draco/versioned/decoders/1.5.5/' );
    dracoLoader.setDecoderConfig( { type: 'js' } );

    // loading manager for all models
    const manager = new THREE.LoadingManager();

    //add loading manager to gltf loader
    const loader = new GLTFLoader(manager);

    //add loading screen animation 
    const loadingScreen = document.getElementById( 'loading-screen' );
    loadingScreen.classList.add( 'fade-out' );
    loadingScreen.addEventListener( 'transitionend', onTransitionEnd );

    //use DRACO compression
    loader.setDRACOLoader( dracoLoader )



    //CAR
    loader.load( 'https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/models%2Fpagani%2Fpagani_v7_compressed.glb?alt=media&token=f9e4fda1-2252-4aa1-8612-1e5a835f0286', function ( gltf ) {

        const model = gltf.scene;
        model.position.set( 0, 0, 0 );
        model.scale.set( 10, 10, 10 );
        scene.add( model );

        model.traverse(obj => {
            if(obj.isMesh) {
                obj.castShadow = true;	    
                obj.receiveShadow = true;
                obj.material.envMap = reflectionCube;	
            }
        });
        
    }, undefined, function ( e ) {

        console.error( e );

    } );



    //GARAGE 
    loader.load( 'https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/models%2Fgarage%2Fgarage_alt_v1_compressed.glb?alt=media&token=287ac3ef-52b4-4072-ae47-06c9e29cd4fa', function ( gltf ) {

        const model = gltf.scene;
        model.position.set( 0, 0, 0 );
        model.scale.set( 7, 10, 10 );
        scene.add( model );
        objects.push(model)    
        model.traverse(obj => {
            if(obj.isMesh) {
                obj.receiveShadow = true;	
                objects.push(obj)
                        
            }
        });



    }, undefined, function ( e ) {

        console.error( e );

    } );


    //LOGO
    loader.load( 'https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/models%2Fgarage%2Flogo.glb?alt=media&token=0c713997-f2fb-4bb1-93a6-8e940b2410af', function ( gltf ) {

        const model = gltf.scene;
        model.position.set( 0, 0, 0 );
        model.scale.set( 10, 10, 10 );
        scene.add( model );

        model.traverse(obj => {
            if(obj.isMesh) {
                obj.castShadow = true;	    
                obj.receiveShadow = true;
                obj.material.envMap = reflectionCube;	
            }
        });
        
    }, undefined, function ( e ) {

        console.error( e );

    } );


    //############################
    // ******** LIGHTS **********
    //###########################

    // ceiling lgt

    
    const ceilingLgt = new THREE.SpotLight(0x81aaf8)
    ceilingLgt.position.x = 0
    ceilingLgt.position.y = 45
    ceilingLgt.position.z = 0
    ceilingLgt.penumbra = .6
    ceilingLgt.angle = .6
    ceilingLgt.castShadow = true
    ceilingLgt.shadow.bias = -0.02
    ceilingLgt.shadow.radius = 9
    ceilingLgt.shadow.camera.near = 10;
    ceilingLgt.shadow.camera.far = 1000; 
    ceilingLgt.shadow.camera.fov = 150; 
    ceilingLgt.shadow.mapSize.width = 1024;
    ceilingLgt.shadow.mapSize.height = 1024;
    ceilingLgt.intensity = 450;
    ceilingLgt.shadow.blurSamples = 10
    ceilingLgt.shadow.radius = 10
    scene.add(ceilingLgt)

    const ceilingLgtWide = new THREE.SpotLight(0x81aaf8)
    ceilingLgtWide.position.x = 0
    ceilingLgtWide.position.y = 45
    ceilingLgtWide.position.z = 0
    ceilingLgtWide.penumbra = .6
    ceilingLgtWide.angle = 1.2
    //ceilingLgtWide.castShadow = true
    ceilingLgtWide.shadow.bias = -0.01
    ceilingLgtWide.shadow.radius = .9
    ceilingLgtWide.shadow.camera.near = 1;
    ceilingLgtWide.shadow.camera.far = 100; 
    ceilingLgtWide.shadow.camera.fov = 150; 
    ceilingLgtWide.shadow.mapSize.width = 1024;
    ceilingLgtWide.shadow.mapSize.height = 1024;
    ceilingLgtWide.intensity = 15;
    scene.add(ceilingLgtWide)


    // LOGO light

    const ceilingLgtLogo = new THREE.SpotLight(0x81aaf8)
    ceilingLgtLogo.position.x = 0
    ceilingLgtLogo.position.y = 68
    ceilingLgtLogo.position.z = -90   
    ceilingLgtLogo.penumbra = .6
    ceilingLgtLogo.angle = 2
    ceilingLgtLogo.castShadow = true
    ceilingLgtLogo.shadow.bias = -0.01
    ceilingLgtLogo.shadow.radius = 9
    ceilingLgtLogo.shadow.camera.near = 10;
    ceilingLgtLogo.shadow.camera.far = 1000; 
    ceilingLgtLogo.shadow.camera.fov = 150; 
    ceilingLgtLogo.shadow.mapSize.width = 1024;
    ceilingLgtLogo.shadow.mapSize.height = 1024;
    ceilingLgtLogo.intensity = 150;
    ceilingLgtLogo.shadow.blurSamples = 10
    ceilingLgtLogo.shadow.radius = 10
    ceilingLgtLogo.distance = 70
    ceilingLgtLogo.target.position.set(0,0,-90)
    scene.add(ceilingLgtLogo)
    scene.add(ceilingLgtLogo.target)



    ///////////
    //HEAD LIGHTS
    //////////

    const headlightL = new THREE.PointLight(0xffffff)
    //headlightL.castShadow = true
    headlightL.position.x = .79*10
    headlightL.position.y = .68*10
    headlightL.position.z = 1.78*10
    headlightL.intensity = 20*10;
    headlightL.distance = .25*10;
    scene.add(headlightL)

    const headlightL2 = new THREE.PointLight(0xffffff)
    //headlightL2.castShadow = true
    headlightL2.position.x = .79*10
    headlightL2.position.y = .60*10
    headlightL2.position.z = 1.89*10
    headlightL2.intensity = 20*10
    headlightL2.distance = .25*10
    scene.add(headlightL2)

    const headlightL3 = new THREE.PointLight(0xffffff)
    //headlightL3.castShadow = true
    headlightL3.position.x = .66*10
    headlightL3.position.y = .60*10
    headlightL3.position.z = 1.95*10
    headlightL3.intensity = 20*10
    headlightL3.distance = .25*10
    scene.add(headlightL3)

    const headlightL4 = new THREE.SpotLight(0xffffff)
    //headlightL4.castShadow = true
    headlightL4.position.x = .79*10
    headlightL4.position.y = .68*10
    headlightL4.position.z = 2.0*10
    headlightL4.intensity = 30*10
    headlightL4.penumbra = .8*10
    headlightL4.angle = .5
    headlightL4.distance = 5*10
    headlightL4.target.position.set(0,-3,9);
    scene.add(headlightL4.target)
    scene.add(headlightL4)


    const headlightR = new THREE.PointLight(0xffffff)
    //headlightR.castShadow = true
    headlightR.position.x = -.79*10
    headlightR.position.y = .68*10
    headlightR.position.z = 1.78*10
    headlightR.intensity = 20*10
    headlightR.distance = .25*10
    scene.add(headlightR)

    const headlightR2 = new THREE.PointLight(0xffffff)
    //headlightR2.castShadow = true
    headlightR2.position.x = -.79*10
    headlightR2.position.y = .60*10
    headlightR2.position.z = 1.89*10
    headlightR2.intensity = 20*10
    headlightR2.distance = .25*10
    scene.add(headlightR2)

    const headlightR3 = new THREE.PointLight(0xffffff)
    //headlightR3.castShadow = true
    headlightR3.position.x = -.66*10
    headlightR3.position.y = .60*10
    headlightR3.position.z = 1.95*10
    headlightR3.intensity = 20*10
    headlightR3.distance = .25*10
    scene.add(headlightR3)

    const headlightR4 = new THREE.SpotLight(0xffffff)
    //headlightR4.castShadow = true
    headlightR4.position.x = -.79*10
    headlightR4.position.y = .68*10
    headlightR4.position.z = 2.0*10
    headlightR4.intensity = 30*10
    headlightR4.penumbra = .8*10
    headlightR4.angle = .5*10
    headlightR4.distance = 5*10
    headlightR4.target.position.set(0,-3,9);
    scene.add(headlightR4.target)
    scene.add(headlightR4)




    RectAreaLightUniformsLib.init();




    //ambient lgt
    const ambLight = new THREE.HemisphereLight(0xffeeb1,0x080820, .3)
    scene.add(ambLight)



    //cubemap BG lighting
    const path = '/textures/hdr3/';
    const format = '.png';
    const urls = [
        path + 'px' + format, path + 'nx' + format,
        path + 'py' + format, path + 'ny' + format,
        path + 'pz' + format, path + 'nz' + format
    ];

    var reflectionCube = new THREE.CubeTextureLoader().load( urls );




}


//load car audio
function load_ignitionAudio(){


}


//################
//AUDIO
//################
function load_audio(){


    if(audioIsLoaded == 0){

        // POSITION AUDIO. ENGINE IS LOUDER NEAR THE CAR
        const listener = new THREE.AudioListener();
        camera.add( listener );

        // create the PositionalAudio object. for idle only
        const sound = new THREE.PositionalAudio( listener );

        // create engine ignition audio. played only on startup
        const ignitionSound = new THREE.Audio(listener);

        

        // load a sound and set it as the PositionalAudio object's buffer
        const audioLoader = new THREE.AudioLoader();

        audioLoader.load( 'https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/audio%2Fevolverse_audio_huayra_engine_start_v01.ogg?alt=media&token=7d87d9b8-71ca-48b9-bc46-4d7b28b73bbc', function( buffer ) {
            ignitionSound.setBuffer( buffer );
            ignitionSound.setVolume( .15 );
            ignitionSound.play();
        });

        //delay function for idle, so ignition can trigger first
        function load_idle(){

        //load idle audio  and have it loop. 
        audioLoader.load( 'https://firebasestorage.googleapis.com/v0/b/evolverse.appspot.com/o/audio%2Fevolverse_audio_huayra_engine_idle_v01.ogg?alt=media&token=6d2ad685-7544-47ab-b900-3f26a9ff95f8', function( buffer ) {
            sound.setBuffer( buffer );
            sound.setRefDistance( 20 );
            sound.setRolloffFactor(3);
            sound.setLoop( true );
            sound.play();
        });
        }


        //delay idle so ignition can trigger first
        setTimeout(load_idle,3000);

        // create an object for the sound to play from
        const sphere = new THREE.SphereGeometry( 1, 1, 1 );
        const material = new THREE.MeshStandardMaterial( { color: 0xff2200 } );
        material.transparent = true;
        material.opacity = 0;
        const mesh = new THREE.Mesh( sphere, material );
        scene.add( mesh );
        

        // add to mesh
        mesh.add( sound );

        //once audio is loaded, sets to 1, so when pointerlock is disabled, the audio doesn't load again (PC mode)
        audioIsLoaded = 1;

    

    }



}





//########################
// PC INIT 
//########################

function init_PC() {

    camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000);
    camera.position.set( 0, 10, 90 );

    scene = new THREE.Scene();


    //load models and lights 
    load_geo_lights()



   //pointer lock controls
    controls = new PointerLockControls(camera, document.body);

    //get entry screen
    const blocker = document.getElementById('blocker');
    const instructions = document.getElementById('instructions');


    

    //lock mouse when click, and load audio
    instructions.addEventListener('click', function () {

        controls.lock();

        //load audio after clicking. audio won't repeat once initially loaded
        load_audio();
        
        

    }, false);

    //close entry screen once camera is locked
    controls.addEventListener('lock', function () {

        instructions.style.display = 'none';
        blocker.style.display = 'none';

    });

    controls.addEventListener('unlock', function () {

        blocker.style.display = 'block';
        instructions.style.display = '';

    });

   
    scene.add(controls.getObject());

    //keyboard controls
    const onKeyDown = function ( event ) {

        switch ( event.code ) {

            case 'ArrowUp':
            case 'KeyW':
                moveForward = true;
                break;

            case 'ArrowLeft':
            case 'KeyA':
                moveLeft = true;
                break;

            case 'ArrowDown':
            case 'KeyS':
                moveBackward = true;
                break;

            case 'ArrowRight':
            case 'KeyD':
                moveRight = true;
                break;

            case 'Space':
                if ( canJump === true ) velocity.y += 350;
                canJump = false;
                break;

        }

    };

    const onKeyUp = function ( event ) {

        switch ( event.code ) {

            case 'ArrowUp':
            case 'KeyW':
                moveForward = false;
                break;

            case 'ArrowLeft':
            case 'KeyA':
                moveLeft = false;
                break;

            case 'ArrowDown':
            case 'KeyS':
                moveBackward = false;
                break;

            case 'ArrowRight':
            case 'KeyD':
                moveRight = false;
                break;

        }

    };

    document.addEventListener( 'keydown', onKeyDown );
    document.addEventListener( 'keyup', onKeyUp );


    //renderer
    renderer = new THREE.WebGLRenderer({
        antialias: true
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.VSMShadowMap
    renderer.physicallyCorrectLights = true
    renderer.toneMapping = THREE.ACESFilmicToneMapping
    renderer.toneMappingExposure = 1
    renderer.outputEncoding = THREE.sRGBEncoding;

    //

    window.addEventListener('resize', onWindowResize);


  
}




//#############################
// MOBILE INIT
//#############################
function init_Mobile() {
    camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000);
    camera.position.set( 0, 10, 90 );
    

    scene = new THREE.Scene();


    //load models and lights
    load_geo_lights()





    //camera control look object
    const sphereLook = new THREE.SphereGeometry( 1, 1, 1 );
    const materialLook = new THREE.MeshStandardMaterial( { color: 0xff2200 } );
    materialLook.transparent = true;
    materialLook.opacity = 0;
    meshLook = new THREE.Mesh( sphereLook, materialLook );
    scene.add( meshLook );
    meshLook.position.copy(camera.position);
    meshLook.rotation.copy(camera.rotation);
    meshLook.updateMatrix();
    meshLook.translateZ(-10);

    
    //camera controls
    controls = new OrbitControls(camera,document.body)
    controls.rotateSpeed = .5
    
   //get entry screem
    const blocker = document.getElementById('blocker');
    const instructions = document.getElementById('instructions');


    //renderer
    renderer = new THREE.WebGLRenderer({
        antialias: true
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.VSMShadowMap
    renderer.physicallyCorrectLights = true
    renderer.toneMapping = THREE.ACESFilmicToneMapping
    renderer.toneMappingExposure = 1
    renderer.outputEncoding = THREE.sRGBEncoding;

  

    // remove overlay on touch screens, and load audio
     window.addEventListener('touchend', function () {

        instructions.style.display = 'none';
        blocker.style.display = 'none';
           //load audio 
        load_audio();



    });




    window.addEventListener('resize', onWindowResize);

}





//#######
// window resize
//#######
function onWindowResize() {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);

}



//########################
// ANIMATE FUNCTION
//########################
function animate() {
        


    //#####
    //PC ANIMATE
    //#####

    const time = performance.now();

    if ( controls.isLocked === true ) {

        
        //pointerlock data
        const delta = ( time - prevTime ) / 1000;

        velocity.x -= velocity.x * 10.0 * delta;
        velocity.z -= velocity.z * 10.0 * delta;

        velocity.y -= 9.8 * 100.0 * delta; // 100.0 = mass

        direction.z = Number( moveForward ) - Number( moveBackward );
        direction.x = Number( moveRight ) - Number( moveLeft );
        direction.normalize(); // this ensures consistent movements in all directions

        if ( moveForward || moveBackward ) velocity.z -= direction.z * 400.0 * delta;
        if ( moveLeft || moveRight ) velocity.x -= direction.x * 400.0 * delta;

        

        controls.moveRight( - velocity.x * delta );
        controls.moveForward( - velocity.z * delta );

        controls.getObject().position.y += ( velocity.y * delta ); // new behavior

        
        if ( controls.getObject().position.y < 10 ) {

            velocity.y = 0;
            controls.getObject().position.y = 10;

            canJump = false;

        }



        //bounding box for camera, stop camera from going through room walls. sorry, not real collision detection..

        if (controls.getObject().position.z >= 92){
            velocity.z = 0;
            controls.getObject().position.z = 91.99;
        }
        if (controls.getObject().position.z <= -86){
            velocity.z = 0;
            controls.getObject().position.z = -85.99;
        }
        if (controls.getObject().position.x >= 64 ){
        
            velocity.x = 0;
            controls.getObject().position.x = 63.99;
        }
        if (controls.getObject().position.x <= -64 ){
        
            velocity.x = 0;
            controls.getObject().position.x = -63.99;
        }


        // IT KIND OF WORKS 
        if (controls.getObject().position.x >= -12 && controls.getObject().position.x <= 14 && controls.getObject().position.z >= -26 && controls.getObject().position.z <= 25){
            
           
            if(controls.getObject().position.x >= 1 && controls.getObject().position.x <= 15){
                controls.getObject().position.x = controls.getObject().position.x + .5
                
            }
            if(controls.getObject().position.x >= -13 && controls.getObject().position.x <= 0){
                controls.getObject().position.x = controls.getObject().position.x - .5
              
            }
            if(controls.getObject().position.z >= 1 && controls.getObject().position.z <= 27){
                controls.getObject().position.z = controls.getObject().position.z + .5
              
            }
            if(controls.getObject().position.z >= -26 && controls.getObject().position.z <= 0){
                controls.getObject().position.z = controls.getObject().position.z - .5
               
            }
            velocity.x = 0
            velocity.z = 0
            moveForward = false
            moveRight = false
            moveLeft = false
            moveBackward = false
        

        }


    }

    prevTime = time;




    //#####
    // MOBILE ANIMATE
    //#####

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        // true for mobile device
        updateCamera();

        //update camera look target for controls similar to pointer lock on PC
        meshLook.position.copy(camera.position);
        meshLook.rotation.copy(camera.rotation);
        meshLook.updateMatrix();
        meshLook.translateZ(-10)
        controls.target.copy(meshLook.position);
        controls.update();

            
            
    }


    
    
requestAnimationFrame( animate );
    renderer.render( scene, camera );

}





//###########################
// UPDATE CAMERA FOR MOBILE 
//###########################
function updateCamera() {
    // move the camera
    const angle = controls.getAzimuthalAngle();
  
    if (fwdValue > 0) {
      tempVector.
      set(0, 0, -fwdValue).
      applyAxisAngle(upVector, angle);
      camera.position.addScaledVector(
      tempVector,
      1);
  
    }
  
    if (bkdValue > 0) {
      tempVector.
      set(0, 0, bkdValue).
      applyAxisAngle(upVector, angle);
      camera.position.addScaledVector(
      tempVector,
      1);
  
    }
  
    if (lftValue > 0) {
      tempVector.
      set(-lftValue, 0, 0).
      applyAxisAngle(upVector, angle);
      camera.position.addScaledVector(
      tempVector,
      1);
  
    }
  
    if (rgtValue > 0) {
      tempVector.
      set(rgtValue, 0, 0).
      applyAxisAngle(upVector, angle);
      camera.position.addScaledVector(
      tempVector,
      1);
  
    }

    //wall collisions
    if (camera.position.z >= 92){
        camera.position.z = 91;
    }
    if (camera.position.z <= -86){
        camera.position.z = -85;
    }
    if (camera.position.x >= 64 ){
    
        camera.position.x = 63;
    }
    if (camera.position.x <= -64 ){
    
        controls.position.x = -63;
    }


    //car collisions
    if (camera.position.x >= -12 && camera.position.x <= 14 && camera.position.z >= -26 && camera.position.z <= 25){
       
       
        if(camera.position.x >= 1 && camera.position.x <= 15){
            camera.position.x = camera.position.x + .5
            
        }
        if(camera.position.x >= -13 && camera.position.x <= 0){
            camera.position.x = camera.position.x - .5
          
        }
        if(camera.position.z >= 1 && camera.position.z <= 27){
            camera.position.z = camera.position.z + .5
          
        }
        if(camera.position.z >= -26 && camera.position.z <= 0){
            camera.position.z = camera.position.z - .5
           
        }
        
    

    }
  
    camera.updateMatrixWorld();

  
  
  };




//#############################
// FUNCTION FOR JOYSTICK
//#############################
  function addJoystick() {
    const options = {
      zone: document.getElementById('joystickWrapper1'),
      size: 120,
      multitouch: false,
      maxNumberOfNipples: 2,
      mode: 'static',
      restJoystick: true,
      shape: 'circle',
      position: { bottom: '100px', left: '100px' },
      dynamicPage: true };
  
  
  
    joyManager = nipplejs.create(options);
  
    joyManager['0'].on('move', function (evt, data) {
      const forward = data.vector.y;
      const turn = data.vector.x;
  
      if (forward > 0) {
        fwdValue = Math.abs(forward);
        bkdValue = 0;
      } else if (forward < 0) {
        fwdValue = 0;
        bkdValue = Math.abs(forward);
      }
  
      if (turn > 0) {
        lftValue = 0;
        rgtValue = Math.abs(turn);
      } else if (turn < 0) {
        lftValue = Math.abs(turn);
        rgtValue = 0;
      }
    });
  
    joyManager['0'].on('end', function (evt) {
      bkdValue = 0;
      fwdValue = 0;
      lftValue = 0;
      rgtValue = 0;
    });
  
  }


//for loading screen
  function onTransitionEnd( event ) {

	event.target.remove();
	
}




